body {
  margin: 0;
  font-family: "OpenSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
button {
  font-family: "OpenSans", sans-serif !important;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./fonts/OpenSans-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./fonts/OpenSans-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}

.recharts-surface {
  overflow: visible;
}
